import { navigate } from 'gatsby'
import React from 'react'
import Link from '../Link/Link'
import styles from './styles.module.scss'
export interface ListItem {
  list_text: string
  details?: string
}

export interface InstructionsProps {
  title: string
  listItems: Array<ListItem>
  text?: string
  cta: string
  videolink?: string
}

const Instructions: React.FC<InstructionsProps> = ({ title, listItems, text, cta, videolink }) => {
  const handleClick = () => navigate('/butiken')

  const inststructionsArr = listItems.splice(0, 1)

  return (
    <>
      {videolink && (
        <iframe
          className={styles.media}
          src={`${videolink}?autoplay=1`}
          width="100%"
          title="instructions-video"
          height="100%"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      <section className={styles.instructions}>
        <h1 className={styles.heading}>{title}</h1>
        {inststructionsArr.map((item, i) => {
          return (
            <React.Fragment key={item.list_text + i}>
              <p className={styles.subtitle}> {item.list_text} </p>
              <p className={styles.subtitle}> {item.details} </p>
            </React.Fragment>
          )
        })}
        <ol className={styles.list}>
          {listItems.map(({ list_text, details }) => (
            <li key={list_text} className={styles.listItem}>
              {list_text} {details && <p className={styles.details}>{details}</p>}
            </li>
          ))}
        </ol>
        {text && <p className={styles.text}>{text}</p>}
        <Link className={styles.cta} href="/butiken" button="white" onClick={handleClick}>
          {cta}
        </Link>
      </section>
    </>
  )
}

export default React.memo(Instructions)
