import { graphql } from 'gatsby'
import React from 'react'
import IndexLayout from '../layouts'
import Instructions from '../components/Instructions/Instructions'
import { ListItem } from '../components/Instructions/Instructions'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'

interface IndexAcf {
  videolink?: string
  title: string
  instructions: Array<ListItem>
  text: string
  cta: string
}
interface IndexProps {
  data: {
    allWordpressPage: {
      edges: [
        {
          node: { acf: IndexAcf }
        }
      ]
    }
  }
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  if (!data.allWordpressPage.edges) return null
  const fields: IndexAcf = get(data, 'allWordpressPage.edges[0].node.acf')
  const { title, instructions, text, cta, videolink } = fields

  const meta = {
    title: 'Start | Klimatbutiken',
    description: 'Välkommen till Klimatbutiken! Ett interaktiv verktyg där du kan lära dig om produkters klimatpåverkan.'
  }

  return (
    <IndexLayout background="black">
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <Instructions title={title} listItems={instructions} text={text} cta={cta} videolink={videolink} />
    </IndexLayout>
  )
}

export default React.memo(IndexPage)

export const query = graphql`
  query ($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          acf {
            videolink
            title
            text
            cta
            instructions {
              list_text
              details
            }
          }
        }
      }
    }
  }
`
